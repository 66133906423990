import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import enProgreso from "../assets/img/muestra-proyecto.png";
import psiconnection from "../assets/img/psiconnection.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects = [
    {
      title: "En progreso",
      description: "Pronto estará disponible",
      imgUrl: enProgreso,
    },
    {
      title: "En progreso",
      description: "Pronto estará disponible",
      imgUrl: enProgreso,
    },
    {
      title: "Psiconnection",
      description: "Aplicación que conecta psicólogos con pacientes",
      imgUrl: psiconnection,
      url: "https://psiconnectiondev.vercel.app/",
    },
  ];
  const projects2 = [
    {
      ttitle: "En progreso",
      description: "Pronto estará disponible",
      imgUrl: enProgreso,
    },
    {
      title: "En progreso",
      description: "Pronto estará disponible",
      imgUrl: enProgreso,
    },
    {
      title: "En progreso",
      description: "Pronto estará disponible",
      imgUrl: enProgreso,
    },
  ];
  const projects3 = [
    {
      title: "En progreso",
      description: "Pronto estará disponible",
      imgUrl: enProgreso,
    },
    {
      title: "En progreso",
      description: "Pronto estará disponible",
      imgUrl: enProgreso,
    },
    {
      title: "En progreso",
      description: "Pronto estará disponible",
      imgUrl: enProgreso,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Proyectos</h2>
                  <p>
                    En esta sección, encontrarás una selección de proyectos que
                    representan mi experiencia y habilidades como programador
                    web. Cada proyecto es una muestra de mi trabajo en
                    diferentes contextos y tecnologías. Desde desafiantes
                    proyectos personales que demuestran mi creatividad y pasión,
                    hasta trabajos académicos y profesionales que destacan mis
                    habilidades técnicas y capacidad para trabajar en equipo.
                    Cada proyecto aquí refleja mi compromiso con la excelencia y
                    mi enfoque en la creación de soluciones web de calidad que
                    sean funcionales y visualmente atractivas.
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">Fullstack</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Frontend</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Backend</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {projects2.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {projects3.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
