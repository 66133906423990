import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo-jotaled.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2-github.svg";
import navIcon3 from "../assets/img/nav-icon3-cv.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a
                href="https://www.linkedin.com/in/jonathan-ledezma-a9a7a91b2/"
                target="_blank"
              >
                <img src={navIcon1} alt="Icon" />
              </a>
              <a href="https://github.com/JotaLed" target="_blank">
                <img src={navIcon2} alt="Icon" />
              </a>
              <a
                href="https://docs.google.com/document/d/11dzXIBFRrXslaABQqu8u5-GpD21DufNZ/edit?usp=sharing&ouid=105562703662718764480&rtpof=true&sd=true"
                target="_blank"
              >
                <img src={navIcon3} alt="Icon" />
              </a>
            </div>
            <p>
              Copyright 2023. Todos los derechos reservados. Los izquierdos
              tambien.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
